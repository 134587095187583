<template>
  <section>
    <section>
      <va-breadcrumbs>
        <va-breadcrumbs-item label="Escolas" to="/educacao/escolas" />
        <va-breadcrumbs-item>
          <span style="font-style: italic; color: #ff7f27"
            >Presença do aluno(a)</span>
        </va-breadcrumbs-item>
      </va-breadcrumbs>
      <h2 class="titulo-links-escolha">Presenças do aluno(a) {{aluno_nome}}</h2>

      <div class="col-md-12">
          <div class="form-group col-md-6">
            <label>Selecione um Mês:</label>
            <select class="form-control" v-model="mes">
                <option  value="janeiro" >Janeiro</option>
                <option  value="fevereiro" >Fevereiro</option>
                <option  value="marco" >Março</option>
                <option  value="abril" >Abril</option>
                <option  value="maio" >Maio</option>
                <option  value="junho" >Junho</option>
                <option  value="julho">Julho</option>
                <option  value="agosto">Agosto</option>
                <option  value="setembro">Setembro</option>
                <option  value="outubro">Outubro</option>
                <option  value="novembro">Novembro</option>
                <option  value="dezembro">Dezembro</option>
            </select>
          </div>
      </div>

      <div class="col-md-12">
          <div class="form-group col-md-6" style="margin-top:15px;">
            <label>Selecione uma Disciplina:</label>
            <select class="form-control" v-model="disciplina_id" @change="buscarPresencasDisciplina()">
                <option  v-if="mes == 0">Selecione um mês</option>
                <option  v-else :value="n.id" v-for="n in disciplina" :key="n">{{n.nome}}</option>
            </select>
          </div>
      </div>

      <br><br>
      <va-data-table
          v-if="presenca.length"
          striped="true"
          hoverable="true"
          animated="true"
          :items="presenca"
          :columns="columns"
          :per-page="perPage"
          :current-page="currentPage"
        >
        <template #cell(id)="{ source: id }">
          <va-button class="mr-2 mb-2" size="small" color="success" @click="mostrar_presenca(id)"> {{ $t('Presenças') }}</va-button>
        </template>

        <template #bodyAppend>
          <tr>
            <td colspan="8" class="table-example--pagination">
              <va-pagination v-model="currentPage" input :pages="pages" />
            </td>
          </tr>
        </template>
      </va-data-table>

      <div class="col-md-12" v-else>
        O aluno não possui informações na data e disciplina selecionada!
      </div>
    </section>
  </section>
</template>


<script>
import { defineComponent } from 'vue'
import { Escola } from "@/class/escolas";
import { LoginInfo } from "@/class/login";
import { Calendario } from "@/class/calendario";
import { Turma } from "@/class/turma";
import { Presenca } from "@/class/presenca";
import { DisciplinaEscolar } from "@/class/disciplina";

export default defineComponent({
  props:{
    etapa_id:{},
    periodo_id:{},
    turma_id:{},
    escola_id:{},
    aluno_id:{},
    aluno_nome:{},
  },
  data () {
    const columns = [
      { key: "dia", label: "Dia", sortable: true },
      { key: "mes", label: "Mês", sortable: true },
      { key: "ano", label: "Ano", sortable: true },
      { key: "presenca1", label: "Situação 1", sortable: true },
      { key: "presenca2", label: "Situação 2", sortable: true },
    ];
    return {
      columns,
      mes:0,
      presenca: [],
      disciplina: [],
      disciplina_id: null,
    }
  },
  computed: {
    pages() {
      return this.perPage && this.perPage !== "0"
        ? Math.ceil(this.presenca.length / this.perPage)
        : this.presenca.length;
    },
  },
  methods:{
    async buscarPresencasDisciplina(){
      return false;
      let payload = {
        mes: this.mes,
        turma_id: this.turma_id,
        disciplina_id: this.disciplina_id,
        aluno_id: this.aluno_id,
      };
      this.presenca = [];
      let data = await Presenca.buscarPresencasDoAluno(payload);
      for (var i = '0'; ; i++) {
        if (data.data[i] == undefined)
          break;
        let novo = {
          ano: data.data[i].ano,
          mes: data.data[i].mes,
          dia: data.data[i].dia,
        }
        if (data.data[i].presenca_1 == 1) {
          novo.presenca1 = "Presente";
        }
        else {
          novo.presenca1 = "Ausente";
        }

        if (data.data[i].presenca_2 == 1) {
          novo.presenca2 = "Presente";
        }
        else if (data.data[i].presenca_2 == 0) {
          novo.presenca2 = "Ausente";
        }
        else {
        }
        this.presenca.push(novo);
      }
      if (this.presenca.length == 0) {
        this.$vaToast.init({
            message: "Presenca do aluno(a) não encontra!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 2500,
            fullWidth: false,
        })
      }
      //ordenar do menor dia para o maior
      this.presenca.sort((a,b) => (a.dia > b.dia) ? 1 : ((b.dia > a.dia) ? -1 : 0));
    },
    async listarPresenca() {
      let payload = {
        escola_id: this.escola_id,
        turma_id: this.turma_id,
      };
      let data = await Presenca.buscarPresencaTurmaConfig(payload);
      this.presenca = [];
    },
    async listaDisciplina(){
      let data = await DisciplinaEscolar.obtemTodosSemId1Turma(this.turma_id);
      for (const el of data.data) {
        if(el.na_turma == 1){
          let novo = {
            id: el.id,
            nome: el.nome,
            status: el.status
          }
          this.disciplina.push(novo);
        }
      }
    }
  },
  beforeMount() {
    if (this.etapa_id == null) {
      this.$router.push({name: 'presencaConfigAll'});
    }
    else {
      this.listaDisciplina();
    }

  }
})
</script>


<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
</style>
